import React from "react";
import Head from "next/head";

import defaultSocialImage from "../../../../public/assets/images/pagepro-og-image.png";

import { SEOProps } from "./types";
import useSeo from "./hooks";

const { NEXT_PUBLIC_APP_DOMAIN } = process.env;

const SEO: React.FC<SEOProps> = ({
  ogTitle,
  ogDescription,
  ogImage,
  ogHref,
  ogType = "website",
  canonicalUrl,
  structuredData
}) => {
  const [breadcrumbs] = useSeo();

  return (
    <Head>
      {canonicalUrl ? (
        <link rel="canonical" href={canonicalUrl} />
      ) : null}
      <title>{ogTitle}</title>
      {ogDescription ? (
        <meta name="description" content={ogDescription} />
      ) : null}
      {ogTitle ? (
        <meta property="og:title" content={ogTitle} key="title" />
      ) : null}
      {ogDescription ? (
        <meta
          property="og:description"
          content={ogDescription}
          key="description"
        />
      ) : null}
      <meta
        property="og:image"
        content={
          ogImage?.filename
            ? ogImage.filename
            : defaultSocialImage.src
        }
      />

      {ogImage?.alt && (
        <meta property="og:image:alt" content={ogImage.alt} />
      )}
      {ogType ? <meta property="og:type" content={ogType} /> : null}
      {ogHref ? <meta property="og:url" content={ogHref} /> : null}
      <meta property="og:locale" content="en_US" />

      <meta
        property="twitter:image"
        content={
          ogImage?.filename
            ? ogImage.filename
            : defaultSocialImage.src
        }
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="format-detection" content="telephone=no" />

      {structuredData?.map((data) => (
        <script key={data._uid} type="application/ld+json">
          {data.content}
        </script>
      ))}

      <script
        key="breadcrumbs-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: NEXT_PUBLIC_APP_DOMAIN
              },
              ...breadcrumbs.map(({ name, url }, index) => ({
                "@type": "ListItem",
                position: index + 2,
                name,
                item: url
              }))
            ]
          })
        }}
      />
    </Head>
  );
};

export default SEO;
