import {
  storyblokEditable,
  StoryblokComponent
} from "@storyblok/react";
import urlJoin from "url-join";

import { removeTrailingSlash } from "@utils/removeTrailingSlash";
import SEO from "@components/utils/Seo";

import { DEFAULT_PAGE_DOMAIN } from "../consts";

import { PageProps } from "./types";
import * as Styled from "./styles";

const { NEXT_PUBLIC_APP_DOMAIN = DEFAULT_PAGE_DOMAIN } = process.env;

const Page: React.FC<PageProps> = ({ blok }) => {
  const {
    backgroundColor,
    body,
    metaTitle: ogTitle,
    metaDescription: ogDescription,
    metaOgImage: ogImage,
    canonical: {
      url = "",
      cached_url: cachedUrl = "",
      story: { full_slug: fullSlug = "" } = {}
    } = {},
    ogUrl: {
      url: ogUrl = "",
      cached_url: ogCachedUrl = "",
      story: { full_slug: ogFullSlug = "" } = {}
    } = {},
    ogType,
    structuredData
  } = blok || {};

  const newestUrl = fullSlug || cachedUrl;
  const canonicalUrl =
    url || newestUrl
      ? urlJoin(
          NEXT_PUBLIC_APP_DOMAIN,
          removeTrailingSlash(newestUrl)
        )
      : "";

  const newestOgUrl = ogFullSlug || ogCachedUrl;
  const ogHref =
    ogUrl || newestOgUrl
      ? urlJoin(
          NEXT_PUBLIC_APP_DOMAIN,
          removeTrailingSlash(newestOgUrl)
        )
      : "";

  return (
    <>
      <SEO
        {...{
          ogTitle,
          ogDescription,
          ogImage,
          ogHref,
          ogType,
          canonicalUrl,
          structuredData
        }}
      />
      {body?.length && (
        <Styled.Wrapper
          backgroundColor={backgroundColor?.color}
          {...storyblokEditable(blok)}
        >
          {body?.map((nestedBlok) => (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
            />
          ))}
        </Styled.Wrapper>
      )}
    </>
  );
};

export default Page;
