import { BreadcrumbProps } from "./types";

const { NEXT_PUBLIC_APP_DOMAIN } = process.env;

export const getBreadcrumbs = (path: string): BreadcrumbProps[] => {
  const { pathname } = new URL(path, "https://fake.domain");

  const pathSegments = pathname
    .split("/")
    .filter((segment) => segment !== "");

  const crumbs: BreadcrumbProps[] = pathSegments?.map(
    (segment, index) => ({
      name: segment
        .split("-")
        .map(
          (word) =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" "),
      url: `${NEXT_PUBLIC_APP_DOMAIN}/${pathSegments
        .slice(0, index + 1)
        .join("/")}`
    })
  );

  return crumbs;
};
