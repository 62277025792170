import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import { BreadcrumbProps } from "./types";
import { getBreadcrumbs } from "./utils";

const useSeo = () => {
  const { asPath } = useRouter();

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>(
    getBreadcrumbs(asPath)
  );

  useEffect(() => {
    const crumbs = getBreadcrumbs(asPath);

    setBreadcrumbs(crumbs);
  }, [asPath]);

  return [breadcrumbs];
};

export default useSeo;
